export const SET_ACTUAL_USER_ID = 'SET_ACTUAL_USER_ID';

export const OPEN_DETAIL_MODAL ='OPEN_DETAIL_MODAL';

export const CLOSE_DETAIL_MODAL ='CLOSE_DETAIL_MODAL';

export const SET_ACTUAL_CAR_ID ='SET_ACTUAL_CAR_ID';

export const REMOVE_CAR = 'REMOVE_CAR';

export const SAVE_CAR = 'SAVE_CAR';

export const SET_CAR_ACTIVE = 'SET_CAR_ACTIVE';

export const CLOSE_CREATE_MODAL ='CLOSE_CREATE_MODAL';

export const OPEN_CREATE_MODAL ='OPEN_CREATE_MODAL';